import React from 'react'
import Link from 'gatsby-link'


class Navi extends React.Component {
    componentDidMount(){
  // Get all "navbar-burger" elements
                const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

                // Check if there are any navbar burgers
                if ($navbarBurgers.length > 0) {

                    // Add a click event on each of them
                    $navbarBurgers.forEach( el => {
                    el.addEventListener('click', () => {

                        // Get the target from the "data-target" attribute
                        const target = el.dataset.target;
                        const $target = document.getElementById(target);

                        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                        el.classList.toggle('is-active');
                        $target.classList.toggle('is-active');

                    });
                    });
                }

    }
    render(){


        return (
<nav className="navbar">
<div className="navbar-brand">
    <div className="navbar-burger burger" data-target="navigationBar">
      <span />
      <span />
    </div>
  </div>

  <div id="navigationBar" className="navbar-menu">
  <div>
    <div className="navbar-start">
      <Link activeClassName="route-link-active" className="navbar-item" to="/">
      Home
      </Link>
      <Link activeClassName="route-link-active" className="navbar-item" to="/stories">
      Stories
      </Link>
      <Link activeClassName="route-link-active" className="navbar-item" to="/tags">
      Tags
      </Link>
      <Link activeClassName="route-link-active" className="navbar-item" to="/dotfiles">
      Dotfiles
      </Link>
    </div>
    </div>
  </div>
</nav>

        )
    }
}
export default Navi