import React from "react"

class Footer extends React.Component {
  render() {
    return (
      <footer className="">
        <p className="text-gray-500 my-4 mt-8 text-xs">© 2018.</p>
        {/* <p className="text-gray-500 my-4 mt-8 text-xs">All rights reserved. © 2018-{new Date().getFullYear()}</p> */}
      </footer>
    )
  }
}

export default Footer